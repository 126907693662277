import React, { useState, useEffect } from 'react';
import '../assets/css/errorpage.css';
function NewLoader() {
    useEffect(()=>{
       
    },[])
    return (
        <>
            <div className="nloader">
           
            </div>
           
        </>
    )
}
export default NewLoader;