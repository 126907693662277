import { DomainService } from "./RecruiterDomainService";
import axios from 'axios';
import { AuthService } from './AuthService';

const baseUrl = DomainService.GetBaseUrl() + "Assignment/";
const getAssignmentList = async (startIndex, pagesize, client, searchText, orderby, assignmentCode) => {
    const userid = AuthService.getCurrentUser().referenceID;

    const url = `${baseUrl}GetAssignmentLists?pageIndex=${startIndex}&pagesize=${pagesize}&encUserId=${userid}&client=${client}&filter=${searchText}&orderby=${orderby}&assignmentCode=${assignmentCode}`;

    const res = await fetch(url, {
        method: "GET",
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': AuthService.getApiAuthorizationConfig()
        },
    });

    if (!res.ok) {
        throw new Error('Network response was not ok ' + res.statusText);
    }

    const data = await res.json();
    return data;
}

const GetJobPostingsByAssignmentId = async (ensassignmentID) => {
    const url = `${baseUrl}GetJobPostingsByAssignmentId?encassignmentId=${ensassignmentID}`;
    const res = await fetch(url, {
        method: "GET",
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': AuthService.getApiAuthorizationConfig()
        },
    });

    if (!res.ok) {
        throw new Error('Network response was not ok ' + res.statusText);
    }

    const data = await res.json();
    return data;
}

const AddOrEditAssignment = async (reqData) => {
    const res = await fetch(baseUrl + "AddAssignments", {
        method: "POST",
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': AuthService.getApiAuthorizationConfig()
        },
        body: JSON.stringify(reqData)
    });
    const data = await res.json();
    return data;
}
const Get_assignmentByUserId = async () => {
    const res = await fetch(baseUrl + "GetAssignmentByUserId?encUserId=" + AuthService.getCurrentUser().referenceID, {
        method: "GET",
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': AuthService.getApiAuthorizationConfig()
        },
    });
    const data = await res.json();
    return data;
}

const GetAssignmentDetailsByAssignmentID = async (encAssignmentId) => {
    const res = await fetch(baseUrl + "GetAllAssignmentDetailsByAssignmentId?encAssignmentId=" + encAssignmentId, {
        method: "GET",
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': AuthService.getApiAuthorizationConfig()
        },
    });
    const data = await res.json();
    return data;
}
const GetClientsCoordinatorDetailsByClientId = async (clientId) => {
    const res = await fetch(baseUrl + "GetClientsCoordinatorDetailsByClientId?clientId=" + clientId, {
        method: "GET",
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': AuthService.getApiAuthorizationConfig()
        },
    });
    const data = await res.json();
    return data;
}
const GetAllJobPostingsByAssignmentId = async (pageIndex, pageSize, assignmentId, clientId, filter, orderby, countryID) => {
    const url = `${baseUrl}GetAllJobPostingByAssignmentID?pageIndex=${pageIndex}&pagesize=${pageSize}&userId=${AuthService.getCurrentUser().referenceID}&assignmentId=${assignmentId}&clientId=${clientId}&filter=${filter}&orderby=${orderby}&countryID=${countryID}`;
    const res = await fetch(url, {
        method: "GET",
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': AuthService.getApiAuthorizationConfig()
        },
    });

    if (!res.ok) {
        throw new Error('Network response was not ok ' + res.statusText);
    }

    const data = await res.json();
    return data;
}

const GetAllCandidatesDetailsByassignment = async (encAssignmentId, documentsXML) => {
    debugger;
    const url = `${baseUrl}GetAllCandidatesDetailsByassignment?encAssignmentId=${encAssignmentId}&documentsXML=${documentsXML}`;
    const res = await fetch(url, {
        method: "GET",
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': AuthService.getApiAuthorizationConfig()
        },
    });

    if (!res.ok) {
        throw new Error('Network response was not ok ' + res.statusText);
    }

    const data = await res.json();
    return data;
}
const setAssignmentTotheCookies = async (encasId) => {
    var expires = "";
    var date = new Date();
    date.setTime(date.getTime() + (7 * 24 * 60 * 60 * 1000));
    expires = "; expires=" + date.toUTCString();
    document.cookie = "_encId" + "=" + (encasId || "") + expires + "; path=/";
}
const getAssignmentCookies = () => {
    var nameEQ = "_encId" + "=";
    var ca = document.cookie.split(';');
    for (var i = 0; i < ca.length; i++) {
        var c = ca[i];
        while (c.charAt(0) === ' ') c = c.substring(1, c.length);
        if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length, c.length);
    }
    return null;
}
const clearAssignmentCookies = () => {
    document.cookie = "_encId" + '=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
}
export const AssignmentService = {
    getAssignmentList,
    GetJobPostingsByAssignmentId,
    AddOrEditAssignment,
    Get_assignmentByUserId,
    GetAssignmentDetailsByAssignmentID,
    GetClientsCoordinatorDetailsByClientId,
    GetAllJobPostingsByAssignmentId,
    GetAllCandidatesDetailsByassignment,
    setAssignmentTotheCookies,
    getAssignmentCookies,
    clearAssignmentCookies
}