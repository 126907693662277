import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom';
import { useLocation } from "react-router-dom";
import { useForm } from "react-hook-form";
import Backdrop from '@mui/material/Backdrop';
import '../../assets/css/recruiter.css';
import loginillustrator from '../../assets/imgs/login-illustrator.svg';
import heroImg from '../../assets/imgs/singin.jpg';
import logoimg from '../../assets/imgs/logo-v1.png';
import { AuthService } from '../../services/AuthService';
import NewLoader from '../../ErrorPage/NewLoader';
import { CryptoService } from '../../services/CryptoService';
import { CookieService } from '../../services/CookieService';


function Login() {
    const navigate = useNavigate();
    const search = useLocation().search;
    let returnURL = new URLSearchParams(search).get('ReturnUrl');
    const qs = new URLSearchParams(search).get('qs');
    const userType = new URLSearchParams(search).get('type');

    const [openLoader, setopenLoader] = React.useState(false);
    const { register, formState: { errors }, handleSubmit } = useForm();
    const [user, setUser] = useState([]);
    const [ErrMessage, setErrMessage] = useState("");
    const [isShowResetBtn, setisShowResetBtn] = useState(false);
    const [lastSuccessLoginUserID, setlastSuccessLoginUserID] = useState("");

    const loginSubmit = async (data) => {

        setisShowResetBtn(false);
        setopenLoader(true);
        const userTypeID = 1;
        setErrMessage("");
        if (data.LoginId !== "CyberN1$nka!") {
            setErrMessage("Invalid login ID or password")
            setopenLoader(false);
         }
        else {
            const res = await AuthService.LoginUser(data.LoginId, data.Password, userTypeID);
            if (res.isSuccess) {
                navigate("/protected")
            } else {
                if (res.isShowResetLoginBtn) {
                    setlastSuccessLoginUserID(data.LoginId);
                    setisShowResetBtn(true);
                } else {
                    setisShowResetBtn(false);
                    setlastSuccessLoginUserID("");
                }
                setopenLoader(false);
                setErrMessage(res.message);
            }
            setopenLoader(false);
        }
    }

    const handleResetBtnClick = async () => {

        const res = await AuthService.ResetLogin(lastSuccessLoginUserID);
        setErrMessage("");
        if (res.isSuccess) {
            setisShowResetBtn(false);
        } else {
            setErrMessage(res.message);
            setisShowResetBtn(true);
        }
    }
    const togglePasswordShow = (inputID, val) => {
        let input = document.getElementById(inputID);
        if (val) {
            if (input.type === "text") {
                input.type = "password";
            } else {
                input.type = "text";
            }
        } else {
            if (input.type === "password") {
                input.type = "text";
            } else {
                input.type = "password";
            }
        }

    }

    useEffect(() => {
        if (AuthService.isAuthenticatedUser()) {
            navigate("/protected");
        }
    }, []);

    
    return (
        <>
            <Backdrop
                sx={{ color: '#FF5C35', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={openLoader}
            >
                <NewLoader />
            </Backdrop>

            <main className="login-container d-flex min-vh-100">
                {/* <div className="graphic-content d-none d-md-flex align-items-center justify-content-center w-50 bg-light">
                    <img src={heroImg} alt="Login-HuntJobs" className="img-fluid" />
                </div> */}
                <div className="login-content d-flex align-items-center justify-content-center w-100 w-md-50 p-4 bg-white">
                    <div className="login-inner-content w-100" style={{ maxWidth: '500px' }}>
                        <div className="logo text-center mb-4">
                            <img src={logoimg} alt="HuntJobs" className="img-fluid" />
                        </div>
                        <div className="header text-center mb-4">
                            <h3 className="title">Hi, Welcome back!</h3>
                            <p>Welcome! Please enter your credentials to log in and access your account.</p>
                        </div>
                        <div className="body">
                            <form method="POST" id="loginForm" className="login" onSubmit={handleSubmit(loginSubmit)}>
                                <div className="form-group mb-3">
                                    <label htmlFor="loginId" className="form-label fw-bold">E-mail Id/ Username</label>
                                    <div className="input-group">
                                        <span className="input-group-text" id="basic-addon1"><i className='bx bxs-user'></i></span>
                                        <input
                                            type="text"
                                            className="form-control"
                                            placeholder="Username"
                                            name="LoginId"
                                            id="loginId"
                                            {...register("LoginId", { required: true })}
                                        />
                                    </div>
                                    {errors.LoginId?.type === 'required' && <span className='text-danger'>Please enter login id</span>}
                                </div>
                                <div className="form-group mb-3">
                                    <label htmlFor="password" className="form-label fw-bold">Password</label>
                                    <div className="input-group">
                                        <span className="input-group-text" id="basic-addon2"><i className='bx bxs-lock'></i></span>
                                        <input
                                            type="password"
                                            className="form-control"
                                            placeholder="●●●●●●●●●●"
                                            name="Password"
                                            id="password"
                                            {...register("Password", { required: true })}
                                        />
                                    </div>
                                    {errors.Password?.type === 'required' && <span className='text-danger'>Please enter password</span>}
                                </div>
                                <div className="form-check mb-3">
                                    <input
                                        className="form-check-input"
                                        type="checkbox"
                                        id="PsdID"
                                        onClick={(e) => togglePasswordShow("password", e.target.checked)}
                                    />
                                    <label className="form-check-label" htmlFor="PsdID">
                                        Show password
                                    </label>
                                </div>
                                <div className="d-grid mb-3">
                                    <button className="btn btn-primary btn-lg">Login</button>
                                </div>
                            </form>
                            <div className="reset-limit text-center">
                                {isShowResetBtn ? (
                                    <>
                                        <p className="label text-danger fw-bold m-0">{ErrMessage}</p>
                                        <button type="button" className="btn btn-primary btn-sm" onClick={handleResetBtnClick}>
                                            <i className='bx bx-reset'></i> Reset
                                        </button>
                                    </>
                                ) : (
                                    <p className="label text-danger fw-bold m-0">{ErrMessage}</p>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </main>



        </>
    )
}
export default Login;


