import React from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import './App.css';
import Candidate from './components/Candidate/Candidate';
import 'bootstrap/dist/css/bootstrap.min.css';
import Login from './components/HomePage/Login';
import ManageKeyWord from './components/Candidate/ManageKeyword';
import SearchPosition2 from './components/Candidate/SearchPosition2';
import Test from './Test';


const PrivateRoute = ({ children }) => {
  const token = localStorage.getItem('token');
  return token ? children : <Navigate to="/" />;
};

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Login />} />
        <Route
          path="/protected"
          element={<Candidate />}
        />
        <Route
          path="/manage-keyword/:candidateId"
          element={<ManageKeyWord />}
        />
        <Route
          path="/search-position-two/:candidateId"
          element={<SearchPosition2 />}
        />
        {/* <Route
          path="/test"
          element={<Test />}
        /> */}

      </Routes>
    </Router>
  );
}

export default App;
