import React, { useCallback, useEffect, useState } from 'react';
import { MdPictureAsPdf } from "react-icons/md";

import Backdrop from '@mui/material/Backdrop';
import NewLoader from '../../ErrorPage/NewLoader';
import { Container } from 'react-bootstrap';
import './can.css';
import candidates from './candidate.json';
import axios from 'axios';
import { AuthService } from '../../services/AuthService';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { AdminService } from '../../services/AdminService';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import DialogTitle from '@mui/material/DialogTitle';
import Draggable from 'react-draggable';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import eye from '../../assets/imgs/login-illustrator.svg';
const MAX_LENGTH_OF_FILE = 1572864;

const BaseUrl = "https://pf4yqilff7.execute-api.ap-south-1.amazonaws.com/Prod/api/";
// const BaseUrl = "https://localhost:51694/api/";

const Candidate = () => {
  const [candidateId, setcandidateId] = useState(null);
  const [candidateHtmlData, setCandidateHtmlData] = useState();
  const [candidateTextData, setCandidateTextData] = useState();
  const [nextCandidateId, setNextCandidateID] = useState()
  const [previousCandidateId, setPreviousCandidateID] = useState()
  const [currentCandidate, setCurrentCandidate] = useState();
  const [keySkills2, setKeySkills2] = useState([]);
  const [keySkillWithoutParse, setKeySkillWithoutParse] = useState("");
  const [candidateInfo, setCandidateInfo] = useState();
  const [currentIndex, setCurrentIndex] = useState(1);
  const [iframeSrc, setiframeSrc] = useState("");
  const [fileViewOpen, setfileViewOpen] = useState(false);
  const [openLoader, setopenLoader] = useState(false);
  const [singleKeySkill2, setSingleKeySkill2] = useState("");
  const [addCandidateTextData, setAddCandidateTextData] = useState("");
  const [blankCandidateInfo, setBlankCandidateInfo] = useState();
  const [position2, setPosition2] = useState("")
  const handleFileViewShow = () => setfileViewOpen(true);
  const handleFileViewClose = () => setfileViewOpen(false);
  const search = useLocation().search;
  const navigate = useNavigate();
  const nav = useNavigate();
  const location = useLocation();
  const [data, setData] = useState(location.state);
  const [dataIndicator, setDataIndicator] = useState(true)

  const handlePrevious = async () => {
    setcandidateId(previousCandidateId)
    document.getElementById('candidateID').value = previousCandidateId
  };

  const handleNext = async () => {
    setcandidateId(nextCandidateId);
    document.getElementById('candidateID').value = nextCandidateId
  }

  const handleLogoutClick = () => {
    AuthService.logout();
    nav("/");
  }

  const handleModalCloseOnKeyPress = (e) => {
    let esc = e.key;
    if (esc === "Escape") {
      handleFileViewClose();
    }
  }

  const IsNullOrEmpty = (v) => {
    if (v === "" || v === null || v === undefined || v === 0 || v === "0") {
      return true;
    } else {
      return false;
    }
  }

  const handleAddKeySkill2 = async () => {

    const processedSkill = singleKeySkill2
      .split(',')
      .map(skill =>
        skill
          .replace(/\s+/g, '') // Remove all spaces
          .replace(/[\/\\\-]+/g, '') // Remove /, \, -, and -- characters
          .toLowerCase() // Convert to lowercase
      );
    console.log(processedSkill);
    try {
      setopenLoader(true);
      const result = await axios.post(BaseUrl + "CanCvParsed/EditCandidateKeySkill2", {
        "Value": candidateId,
        "Skills": JSON.stringify(processedSkill)
      });
      if (result?.data?.isSuccess) {
        // alert("Inserted Successfully");
        await fetchCandidateData()
      }
    } catch (error) {
      console.log(error.message);
    } finally {
      setopenLoader(false);
    }

    // Clear the input field
    setSingleKeySkill2('');
  };

  const PaperComponent = (props) => {
    return (
      <Draggable
        handle="#draggable-dialog-title"
        cancel={'[class*="MuiDialogContent-root"]'}
      >
        <Paper {...props} />
      </Draggable>
    );
  }

  const handleViewResumeClick = async (encCandidateID, encJobPostingID, statusID) => {
    setopenLoader(true);
    try {
      const response = await AdminService.viewCandidateDocument(candidateInfo.EncCandidateID);
      if (response.isSuccess) {
        const resumeData = JSON.parse(response.apiData);
        if (!IsNullOrEmpty(resumeData[0].Base64File)) {
          let fileExt = resumeData[0].FileName.split('.').pop().toLowerCase();
          if (resumeData[0].Base64File.includes('data:application')) {
            if (fileExt === 'xlsx' || fileExt === 'csv' || fileExt === 'xls' || fileExt === 'doc' || fileExt === 'docx') {
              let fileExt = resumeData[0].FileName.split('.').pop().toLowerCase();
              const dlFileName = resumeData[0].FileName.replace(/\.[^/.]+$/, "") + "_" + AuthService.getFileNameForCurrentTimeStamp() + "." + fileExt;
              download_View_File(resumeData[0].Base64File, dlFileName);
            } else {
              viewCandidateResume(resumeData[0].Base64File, resumeData[0].FileName);
            }
          } else {
            if (fileExt === 'xlsx' || fileExt === 'csv' || fileExt === 'xls' || fileExt === 'doc' || fileExt === 'docx') {
              let fileExt = resumeData[0].FileName.split('.').pop().toLowerCase();
              const dlFileName = resumeData[0].FileName.replace(/\.[^/.]+$/, "") + "_" + AuthService.getFileNameForCurrentTimeStamp() + "." + fileExt;
              if (resumeData[0].Base64File === "error") {
                alert(0, "Resume not found");
              } else {
                download_View_File(atob(resumeData[0].Base64File), dlFileName);
              }
            } else {
              if (resumeData[0].Base64File === "error") {
                alert(0, "Resume not found");
              } else {
                viewCandidateResume(atob(resumeData[0].Base64File), resumeData[0].FileName);
              }
            }
            // downloadFile(atob(resumeData[0].Base64File), resumeData[0].FileName);
          }
        } else {
          alert(0, "Resume not found.");
        }
      } else {

      }

    } catch (error) {
      console.log(error);
    }
    setopenLoader(false);
  }

  const download_View_File = (base64, fileName) => {
    let fileExt = fileName.split('.').pop().toLowerCase();
    const link = document.createElement('a');
    if (base64.includes('data:application')) {
      link.href = base64;
      link.download = fileName;
      link.click();
    } else {
      switch (fileExt) {
        case "docx":
        case "doc":
          link.href = `data:application/vnd.openxmlformats-officedocument.wordprocessingml.document;base64,${base64}`;
          break;
        case "xls":
          link.href = `data:application/vnd.ms-excel;base64,${base64}`;
          break;
        case "xlsx":
          link.href = `data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,${base64}`;
          break;
        case "pdf":
          link.href = `data:application/pdf;base64,${base64}`;
          break;
        case "text":
          link.href = `data:text/plain;base64,${base64}`;
          break;
        default:
          break;
      }
      link.download = fileName;
      link.click();
    }
  }

  const viewCandidateResume = (base64, fileName) => {
    let fileExt = fileName.split('.').pop().toLowerCase();
    const link = document.createElement('a');
    if (base64.includes('data:application') && base64.length <= MAX_LENGTH_OF_FILE) {
      setiframeSrc(base64);
      handleFileViewShow();
    } else {
      switch (fileExt) {
        case "docx":
        case "doc":
          link.href = `data:application/vnd.openxmlformats-officedocument.wordprocessingml.document;base64,${base64}`;
          break;
        case "xls":
          link.href = `data:application/vnd.ms-excel;base64,${base64}`;
          break;
        case "xlsx":
          link.href = `data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,${base64}`;
          break;
        case "pdf":
          if (!base64.includes('data:application') && base64.length <= MAX_LENGTH_OF_FILE) {
            setiframeSrc(`data:application/pdf;base64,${base64}`);
            handleFileViewShow();
          } else {
            link.href = base64;
          }
          // setiframeSrc(`data:application/pdf;base64,${base64}`);
          // handleFileViewShow();
          break;
        case "text":
          link.href = `data:text/plain;base64,${base64}`;
          break;
        default:
          break;
      }
      link.download = fileName;
      link.click();
    }
  }

  const handleAddResumeData = async () => {
    try {
      setopenLoader(true);
      const result = await axios.post(BaseUrl + "CanCvParsed/AddTextInResumeText", {
        "Value": candidateId,
        "Skills":  addCandidateTextData
      });
      if (result?.data?.isSuccess) {
        // alert("Inserted Successfully");
        await fetchCandidateData()
      }
      setopenLoader(false);
      setAddCandidateTextData("");
    }
    catch (error) {
      // console.log(error.message);
    }
  }

  const debounce = (func, delay) => {
    let debounceTimer;
    return function (...args) {
      const context = this;
      clearTimeout(debounceTimer);
      debounceTimer = setTimeout(() => func.apply(context, args), delay);
    };
  };

  const handleCandidateIDChange = (value) => {
    setcandidateId(value);
  };

  const handleManageKeyWord = () => {
    navigate({
      pathname: `/manage-keyword/${candidateId}`
    }, { state: candidateId });
  }

  const handleSearchPosition2 = () => {
    // navigate("/search-position-two");
    navigate({
      pathname: `/search-position-two/${candidateId}`
    }, { state: candidateId });
  }

  const handleAddPosition2 = async () => {
    try {
      const position2WithoutSpace = position2
        .replace(/\s+/g, '') // Remove all spaces
        .replace(/[\/\\\-]+/g, '') // Remove /, \, -, and -- characters
        .toLowerCase(); // Convert to lowercase
      const result = await axios.post(BaseUrl + "CanCvParsed/InsertPositionTwo", {
        "Value": candidateId,
        "Skills": position2WithoutSpace
      });
      if (result?.data?.isSuccess) {
        // alert("Position 2 Inserted SuccessFully");
        await fetchCandidateData()
        setPosition2("");
      }
    }
    catch (error) {
      console.log(error.message);
    }
  }

  const handleNextBlank = () => {
    setcandidateId(blankCandidateInfo.CandidateIDGreater);
    document.getElementById("candidateID").value = blankCandidateInfo.CandidateIDGreater;
  }

  const handlePreviousBlank = () => {
    setcandidateId(blankCandidateInfo.CandidateIDLess);
    document.getElementById("candidateID").value = blankCandidateInfo.CandidateIDLess;
  }

  const fetchCandidateData = async () => {
    try {
      let result;
      setopenLoader(true);
      if (data && dataIndicator) {
        result = await axios.post(BaseUrl + "CanCvParsed/GetCandidateCv", {
          "Value": data
        });
        document.getElementById("candidateID").value = data
        setData(null); // Clear the data after use
        setDataIndicator(false);
      }
      else {
        result = await axios.post(BaseUrl + "CanCvParsed/GetCandidateCv", {
          "Value": candidateId
        });
      }
      if (result?.data?.isSuccess) {
        // Ensure data is defined and valid before parsing
        const rawData = result.data.data;
        // Handle potential undefined or null data
        let data = [];
        if (rawData) {
          try {
            data = JSON.parse(rawData);
          } catch (error) {
            console.error("Error parsing JSON data:", error);
            data = [];
          }
        }
        setCandidateHtmlData(data[0]?.CandidateResumeHTML || '');
        setCandidateTextData(data[0]?.CandidateResumeDetails || '');
        setCandidateInfo(data[0] || {});
        setPreviousCandidateID(data[0]?.PreviusID || null);

        // Check if CandidateKeySkills2 exists and is a valid JSON
        if (data[0]?.CandidateKeySkills2) {
          try {
            setKeySkills2(JSON.parse(data[0]?.CandidateKeySkills2));
          } catch (error) {
            console.error("Error parsing CandidateKeySkills2:", error);
            setKeySkills2(data[0]?.CandidateKeySkills2);
          }
        } else {
          setKeySkills2([]);
        }
        setKeySkillWithoutParse(data[0]?.CandidateKeySkills2);
        setCurrentCandidate(data[0]?.CandidateID || null);
        setNextCandidateID(data[0]?.NextID || null);
      }
    } catch (error) {
      console.error("Error fetching candidate data:", error);
    } finally {
      setopenLoader(false);
    }
  };

  const debouncedHandleCandidateIDChange = useCallback(debounce(handleCandidateIDChange, 500), []);

  useEffect(() => {
    const getLastCandidateData = async () => {
      let result = await axios.get(BaseUrl + "CanCvParsed/getLastCandidateId");
      const parsedData = JSON.parse(result.data.data)
      setcandidateId(parsedData[0].CandidateID);
      document.getElementById("candidateID").value = parsedData[0]?.CandidateID;
    }
    getLastCandidateData();
  }, [])

  // if press back from search exact profile position 
  // useEffect(() => {
  //   const checkNavigatedBack = async () => {
  //     if (data) {
  //       setcandidateId();
  //       await fetchCandidateData();
  //     }
  //   }
  //   checkNavigatedBack();
  // }, [])

  //get blankKeySkillCandidate
  useEffect(() => {
    const getblankKeySkillCandidate = async () => {
      let result = await axios.post(BaseUrl + "CanCvParsed/GetBlankCandidateKeySkillId", {
        "Value": candidateId
      }
      );
      const parsedData = JSON.parse(result.data.data)[0];
      setBlankCandidateInfo(parsedData);
    }
    if (candidateId) {
      getblankKeySkillCandidate();
    }
  }, [candidateId]);

  //brings all candidate Data
  useEffect(() => {
    if (candidateId) {
      fetchCandidateData();
    }
  }, [candidateId]);

  //refresh button 

  const handleRefresh = () => {
    window.location.reload();
  };

  //  useEffect(() => {
  //        if (AuthService.isAuthenticatedUser()) {
  //            const data = AuthService.getCurrentUser();
  //            if (parseInt(data.agencyID) === 4 && parseInt(data.roleId) == 13 && data.userName === "Ashish Behera") {
  //                navigate({
  //                    pathname: '/',
  //                })
  //            }
  //            else {
  //                AuthService.logout();
  //                navigate({
  //                    pathname: '/login',
  //                })
  //            }
  //        }

  //    }, []);

  useEffect(() => {
    if (!AuthService.isAuthenticatedUser()) {
      navigate('/');
    }

  }, []);

  return (
    <>
      <Backdrop
        sx={{ color: '#FF5C35', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={openLoader}
      >
        <NewLoader />
      </Backdrop>


      {/* File view Modal */}
      {iframeSrc !== '' &&
        <Dialog
          onKeyDown={handleModalCloseOnKeyPress}
          className='candidate-lg-modal'
          fullWidth
          maxWidth='lg'
          open={fileViewOpen}
          onHide={handleFileViewClose}
          PaperComponent={PaperComponent}
          aria-labelledby="draggable-dialog-title">
          <DialogTitle style={{ cursor: "move" }} id="draggable-dialog-title" className='d-flex justify-content-between align-items-center'>
            <span>View resume</span>
            <Button color='error' autoFocus onClick={() => setfileViewOpen(false)} className="btn-close float-right btn-sm ">

            </Button>
          </DialogTitle>
          <DialogContent>

            <object
              data={iframeSrc}
              type="application/pdf"
              width="100%"
              height="700px"
            >
              <p>PDF could not be displayed</p>
            </object>
          </DialogContent>
          <DialogActions>
            <Button color='error' autoFocus onClick={() => setfileViewOpen(false)}>
              Close
            </Button>
          </DialogActions>
        </Dialog>
      }

      <div style={{
        display: "flex",
        flexDirection: "row-reverse",
        gap: "20px",
        margin: "10px"
      }}>
        <div ><button className="btn btn-success" onClick={handleSearchPosition2}>Search Position 2</button></div>
        <div ><button className="btn btn-success" onClick={handleManageKeyWord}>Search Exact Profile Position</button></div>
      </div>
      <div
        style={{ cursor: "pointer", position: "fixed", top: "10px", left: "10px", zIndex: 1000 }}
      >
        <button type="button" className="btn btn-danger" onClick={() => handleLogoutClick()}>LogOut</button>
        <button className=' btn btn-primary' style={{ marginLeft: "15px" }} onClick={handleRefresh}>Refresh</button>
      </div>
      <div>
        <div className="container-xl mt-5">
          <div className='container'>
            <div className='row'>
              <div className="col col-lg-6 candidate-info mb-4 p-3 bg-white shadow-sm rounded">
                <h5 className="text-primary">
                  <i className="bi bi-person-circle me-2"></i>
                  Name: {candidateInfo?.CandidateFirstName}
                </h5>
                <h5 className="text-primary">
                  <i className="bi bi-person-circle me-2"></i>
                  Age: {candidateInfo?.age || "Not found"}
                </h5>
                <h5 className="text-primary">
                  <i className="bi bi-person-circle me-2"></i>
                  Profile Position: {candidateInfo?.CandidateCurrentPosition}
                </h5>
                <h5 className="text-primary">
                  <i className="bi bi-person-circle me-2"></i>
                  Industry: {candidateInfo?.IndustryName}
                </h5>
                <h5 className="text-primary">
                  <i className="bi bi-phone me-2"></i>
                  Mobile No: {candidateInfo?.CandidateMobileNo}
                </h5>
                <h5 className="text-primary">
                  <i className="bi bi-envelope me-2"></i>
                  Email ID: {candidateInfo?.CandidateEmailID}
                </h5>
                <h5 className="text-primary">
                  <i className="bi bi-envelope me-2"></i>
                  ID: {currentCandidate}
                </h5>
                <h5 className="text-success">
                  <i className="bi bi-envelope me-2"></i>
                  Last  ID: {blankCandidateInfo?.LastCandidateid}
                </h5>
              </div>
              <div className="col-lg-6 mb-4 p-3 bg-light rounded">
                <div className="row align-items-center">
                  <div className="col-md-6">
                    <label htmlFor="candidateID" className="form-label">Enter Candidate ID:</label>
                  </div>
                  <div className="col-md-6">
                    <input
                      type="text"
                      id="candidateID"
                      className="form-control"
                      onChange={(e) => debouncedHandleCandidateIDChange(e.target.value)}
                      placeholder="Enter Candidate ID"
                    />
                  </div>
                </div>
              </div>



            </div>
          </div>
          <div className=" p-4 bg-white shadow-sm rounded" style={{ borderRadius: '20px' }}>
            <div className='d-flex justify-content-between '>
              <p>
                {
                  candidateInfo?.FileName ?
                    <a style={{ cursor: "pointer" }} onClick={handleViewResumeClick}>{candidateInfo?.FileName} : <MdPictureAsPdf size={40} color='#F24D1D' /> </a>
                    :
                    <p>
                      No Resume Found
                    </p>
                }
              </p>
              <div>
                <button
                  variant="warning"
                  onClick={handlePreviousBlank}
                  disabled={currentIndex === 0}
                  className="me-2 rounded-pill btn btn-success"
                >
                  Previous  Blank
                </button>
                <button
                  variant="warning"
                  onClick={handleNextBlank}
                  disabled={currentIndex === 0}
                  className="me-2 rounded-pill btn btn-success"
                >
                  Next Blank
                </button>
              </div>

            </div>

            <div className="container mb-4">
              <div class="row justify-content-between">
                {/* <div className="col col-lg-2  mb-3 p-3 bg-light rounded overflow-auto" style={{ maxHeight: '500px' }}>
                  <h4 className="text-secondary">
                    <i className="bi bi-file-earmark-text me-2"></i>
                    Candidate HTML Data
                  </h4>
                  {
                    candidateHtmlData ?
                      <p>{candidateHtmlData}</p>
                      :
                      <div className="alert alert-danger" role="alert">
                        No Candidate Html Data Found
                      </div>
                  }
                </div> */}


                <div className="col col-lg-4  p-3 bg-light rounded overflow-auto" style={{ maxHeight: '500px' }}>
                  <h4 className="text-secondary">
                    <i className="bi bi-file-earmark-text me-2"></i>
                    Candidate Resume Data
                  </h4>
                  {
                    candidateTextData ?
                      <p>{candidateTextData}</p>
                      :
                      <div className="alert alert-danger" role="alert">
                        No Candidate Resume Data Found
                      </div>
                  }
                </div>


                <div className="col col-lg-4 p-3 bg-light rounded overflow-auto" style={{ maxHeight: '500px' }}>
                  <div className="d-flex justify-content-between align-items-center mb-3">
                    <h4 className="text-secondary m-0">
                      <i className="bi bi-file-earmark-text me-2"></i>
                      Key Skills 2
                    </h4>

                  </div>
                  {keySkillWithoutParse ? (
                    <textarea
                      className="form-control"
                      placeholder="Enter key skills here..."
                      rows={18}
                      // value={keySkills2}
                      value={keySkillWithoutParse}
                      // onChange={(e) => setKeySkills2(e.target.value)} // Assuming you have a state setter for keySkills2
                      style={{ resize: 'none' }}
                      readOnly
                    ></textarea>
                  ) : (
                    <div className="alert alert-danger" role="alert">
                      No Candidate Resume Data Found
                    </div>
                  )}
                </div>


                <div className="col col-lg-2 p-3 bg-light rounded overflow-auto" style={{ maxHeight: '500px' }}>
                  <h4 className="text-secondary m-0">
                    <i className="bi bi-file-earmark-text me-2"></i>
                    Position 2
                  </h4>


                  <textarea
                    className="form-control"
                    placeholder="Position 2 will be displayed here."
                    rows={6}
                    // value={keySkills2}
                    value={candidateInfo?.Position2 || "Not found"}
                    // onChange={(e) => setKeySkills2(e.target.value)} // Assuming you have a state setter for keySkills2
                    style={{ resize: 'none' }}
                    readOnly
                  ></textarea>


                </div>
              </div>

              <div className="row justify-content-between align-items-center" style={{ margin: "60px 0" }}>
                {/* <div className="col col-lg-3 text-center">

                </div> */}
                <div className="col col-lg-4">
                  <div className='text-center'>
                    <textarea
                      className="form-control"
                      // placeholder="Enter Text Here..."
                      rows={10}
                      value={addCandidateTextData}
                      onChange={(e) => setAddCandidateTextData(e.target.value)} // Assuming you have a state setter for keySkills2
                      style={{ resize: 'none', marginRight: "10px" }}
                    ></textarea>
                    <button onClick={handleAddResumeData} className="mt-3 btn btn-primary  btn-lg">Replace</button>
                  </div>
                </div>
                <div className="col col-lg-4" >
                  <div className='text-center'>
                    <textarea
                      className="form-control p-2"
                      // placeholder="Enter a key skill here..."
                      rows={10}
                      value={singleKeySkill2}
                      onChange={(e) => setSingleKeySkill2(e.target.value)}
                      style={{ resize: 'none', marginRight: "10px" }}
                    ></textarea>
                    <button onClick={handleAddKeySkill2} className="mt-3 btn btn-primary  btn-lg">Replace</button>
                  </div>
                </div>
                <div className="col col-lg-2">
                  <div className='text-center'>
                    <textarea
                      className="form-control p-2"
                      // placeholder="Enter Candidate Position below , if space or any special character found in position"
                      rows={10}
                      value={position2}
                      onChange={(e) => setPosition2(e.target.value)}
                      style={{ resize: 'none', marginRight: "10px" }}
                    ></textarea>
                    <button onClick={handleAddPosition2} className="mt-3 btn btn-primary  btn-lg">Replace</button>
                  </div>
                </div>
              </div>

            </div>

          </div>
        </div>
        <div className="footer-buttons d-flex justify-content-center " style={{ marginTop: "50px" }}>
          <button
            variant="warning"
            onClick={handlePrevious}
            disabled={currentIndex === 0}
            className="me-2 rounded-pill btn btn-success"
          >
            <i className="bi bi-arrow-left-circle me-2"></i>
            Previous
          </button>
          <button
            variant="warning"
            onClick={handleNext}
            disabled={currentIndex === candidates.length - 1}
            className="rounded-pill btn btn-success"
          >
            Next
            <i className="bi bi-arrow-right-circle ms-2"></i>
          </button>
        </div>
      </div>
    </>
  );
};

export default Candidate;
