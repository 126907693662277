import { AuthService } from './AuthService';
import { DomainService } from './RecruiterDomainService';
const baseUrl = DomainService.GetBaseUrl() + "AdvanceResumeSearch/";


const getAdvanceSearchResult = async (formdata) => {
    const res = await fetch(baseUrl + "GetAdvanceSearchResult", {
        method: "POST",
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': AuthService.getApiAuthorizationConfig()
        },
        body: JSON.stringify(formdata)
    });

    const data = await res.json();
    return data;
}
const saveAdvanceSearchResult = async (formdata) => {
    const res = await fetch(baseUrl + "SaveSearch", {
        method: "POST",
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': AuthService.getApiAuthorizationConfig()
        },
        body: JSON.stringify(formdata)
    });

    const data = await res.json();
    return data;
}
const resumeMoveToFolder = async (folderid, resumeIds) => {
    const res = await fetch(baseUrl + "ResumeMovedToFolder?folderId=" + folderid + "&resumeIDs=" + resumeIds + "&UserID=" + parseInt(AuthService.getCurrentUser().userId), {
        method: "POST",
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': AuthService.getApiAuthorizationConfig()
        },

    });

    const data = await res.json();
    return data;
}
const createNewFolder = async (folderid, folderName) => {
    const res = await fetch(baseUrl + "SaveNewFolder?folderId=" + folderid + "&folderName=" + folderName + "&UserID=" + parseInt(AuthService.getCurrentUser().userId), {
        method: "POST",
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': AuthService.getApiAuthorizationConfig()
        },

    });

    const data = await res.json();
    return data;
}
const createNewJobPostingFolder = async (folderId, folderName, jobpostingid) => {
    const folderdata = {
        FolderId: folderId,
        FolderName: folderName,
        UserID: parseInt(AuthService.getCurrentUser().userId),
        JobPostingID: parseInt(jobpostingid)
    }
    const res = await fetch(baseUrl + "JobPostingFolder", {
        method: "POST",
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': AuthService.getApiAuthorizationConfig()
        },
        body: JSON.stringify(folderdata)
    });

    const data = await res.json();
    return data;
}
const downloadSearchCandidateToExcel = async (CandidateIDs) => {
    const res = await fetch(baseUrl + "GetSearchCandidateExcelDownload?candidateXml=" + CandidateIDs + "&userID=" + AuthService.getCurrentUser().referenceID, {
        method: "POST",
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': AuthService.getApiAuthorizationConfig()
        },
    });
    const data = await res.json();
    return data;
}


const check_JobCode_Available = async (jobCode) => {
    const res = await fetch(baseUrl + "CheckJobCodeExist?jobCode=" + jobCode, {
        method: "POST",
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': AuthService.getApiAuthorizationConfig()
        },
    });
    const data = await res.json();
    return data;
}

const get_AutoCv_SearchResult_By_JobCode = async (reqData) => {
    const res = await fetch(baseUrl + "GetAutoCvSearchResultByJobCode", {
        method: "POST",
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': AuthService.getApiAuthorizationConfig()
        },
        body: JSON.stringify(reqData)
    });
    const data = await res.json();
    return data;
}
const init_Process_ForSendCv_To_Recruiter = async (reqData) => {
    const res = await fetch(baseUrl + "InitProcessForSendCvToRecruiter", {
        method: "POST",
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': AuthService.getApiAuthorizationConfig()
        },
        body: JSON.stringify(reqData)
    });
    const data = await res.json();
    return data;
}

const init_Process_ForSend_AppliedCv_To_Recruiter = async (reqData) => {
    const res = await fetch(baseUrl + "InitProcessForSendAppliedCvToRecruiter", {
        method: "POST",
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': AuthService.getApiAuthorizationConfig()
        },
        body: JSON.stringify(reqData)
    });
    const data = await res.json();
    return data;
}
const get_AutoCv_SearchLink_By_JobCode = async (reqData) => {
    const res = await fetch(baseUrl + "GetAutoCvSearchLinkByJobCode", {
        method: "POST",
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': AuthService.getApiAuthorizationConfig()
        },
        body: JSON.stringify(reqData)
    });
    const data = await res.json();
    return data;
}


const GetManagedKeyWord = async (keyword) => {
    const url = `${baseUrl}SearchManageKeyword?keyword=${keyword}`;
    const res = await fetch(url, {
        method: "GET",
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': AuthService.getApiAuthorizationConfig()
        },
    });
    const data = await res.json();
    return data;
}

const GetManagedPosition2 = async (keyword) => {
    const url = `${baseUrl}SearchManagePositionTwo?keyword=${keyword}`;
    const res = await fetch(url, {
        method: "GET",
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': AuthService.getApiAuthorizationConfig()
        },
    });
    const data = await res.json();
    return data;
}


const escapeHtml = (input) => {
    return input.replace(/&/g, "&amp;")
        .replace(/</g, "&lt;")
        .replace(/>/g, "&gt;")
        .replace(/"/g, "&quot;")
        .replace(/'/g, "&apos;")
        .replace(/¢/g, "&cent;")
        .replace(/£/g, "&pound;")
        .replace(/¥/g, "&yen;")
        .replace(/€/g, "&euro;")
        .replace(/©/g, "&copy;")
        .replace(/®/g, "&reg;")
        .replace(/™/g, "&trade;")
        .replace(/°/g, "&deg;")
        .replace(/µ/g, "&micro;")
        .replace(/π/g, "&pi;")
        .replace(/∑/g, "&sum;")
        .replace(/∆/g, "&Delta;")
        .replace(/√/g, "&radic;")
        .replace(/≈/g, "&asymp;")
        .replace(/≠/g, "&ne;")
        .replace(/≤/g, "&le;")
        .replace(/≥/g, "&ge;");
}

const unescapeHtml = (input) => {
    return input.replace(/&amp;/g, "&")
        .replace(/&lt;/g, "<")
        .replace(/&gt;/g, ">")
        .replace(/&quot;/g, "\"")
        .replace(/&apos;/g, "'")
        .replace(/&cent;/g, "¢")
        .replace(/&pound;/g, "£")
        .replace(/&yen;/g, "¥")
        .replace(/&euro;/g, "€")
        .replace(/&copy;/g, "©")
        .replace(/&reg;/g, "®")
        .replace(/&trade;/g, "™")
        .replace(/&deg;/g, "°")
        .replace(/&micro;/g, "µ")
        .replace(/&pi;/g, "π")
        .replace(/&sum;/g, "∑")
        .replace(/&Delta;/g, "∆")
        .replace(/&radic;/g, "√")
        .replace(/&asymp;/g, "≈")
        .replace(/&ne;/g, "≠")
        .replace(/&le;/g, "≤")
        .replace(/&ge;/g, "≥");
}

const GetSpecificSearchResult = async (formdata) => {
    const res = await fetch(baseUrl + "GetSpecificSearchResult", {
        method: "POST",
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': AuthService.getApiAuthorizationConfig()
        },
        body: JSON.stringify(formdata)
    });

    const data = await res.json();
    return data;
}

export const AdvancesearchResultService = {
    getAdvanceSearchResult,
    saveAdvanceSearchResult,
    resumeMoveToFolder,
    createNewFolder,
    createNewJobPostingFolder,
    downloadSearchCandidateToExcel,
    check_JobCode_Available,
    get_AutoCv_SearchResult_By_JobCode,
    escapeHtml,
    unescapeHtml,
    init_Process_ForSendCv_To_Recruiter,
    get_AutoCv_SearchLink_By_JobCode,
    init_Process_ForSend_AppliedCv_To_Recruiter,
    GetManagedKeyWord,
    GetSpecificSearchResult,
    GetManagedPosition2
}