import { AuthService } from "./AuthService";
import { DomainService } from "./RecruiterDomainService";
const baseUrl = DomainService.GetBaseUrl() + "Auth/";

const Login = async (loginId, password, userTypeID) => {
    const item = {
        LoginId: loginId,
        Password: password,
        UserTypeID: userTypeID
    }
    const res = await fetch(baseUrl + "Login", {
        method: "POST",
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
           // "H_Key": AuthService.getAuthHeaderKey()
        },
        body: JSON.stringify(item)
    });
    const data = await res.json();
    return data;
}
const ResetLogin = async (currentUserID) => {

    const res = await fetch(baseUrl + "ResetUserLogin?currentUserID=" + currentUserID, {
        method: "POST",
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        },
    });
    const data = await res.json();
    return data;
}
const Logout = async () => {

    const res = await fetch(baseUrl + "Logout?loginID=" + AuthService.getCurrentUser().loginId + "&userTypeID=" + AuthService.getCurrentUser().userTypeId, {
        method: "POST",
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        },
    });
    const data = await res.json();
    return data;
}
const changeUserPassword = async (oldPassword, newConfirmPassword) => {

    const item = {
        UserID: AuthService.getCurrentUser().userId,
        OldPassword: oldPassword,
        NewPassword: newConfirmPassword
    }
    const res = await fetch(baseUrl + "Changepassword", {
        method: "POST",
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': AuthService.getApiAuthorizationConfig()
        },
        body: JSON.stringify(item)
    });
    const data = await res.json();
    return data;
}
const getNotifiationSettings = async () => {
    const res = await fetch(baseUrl + "GetNotificationSetting?referenceID=" + parseInt(AuthService.getCurrentUser().referenceID), {
        method: "Get",
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': AuthService.getApiAuthorizationConfig()
        },
    });
    const data = await res.json();
    return data;
}
const getCountrydetailsByIp = async () => {
    const res = await fetch(baseUrl + "GetCountrydetailsByIp", {
        method: "Get",
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        },
    });
    const data = await res.json();
    return data;
}
const getAllMenusByUserID = async () => {
    const res = await fetch(baseUrl + "GetAllMenusByUserID?userID=" + AuthService.getCurrentUser().userId, {
        method: "GET",
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': AuthService.getApiAuthorizationConfig()
        },
    });
    const data = await res.json();
    return data;
}
export const UserService = {
    Login,
    ResetLogin,
    Logout,
    changeUserPassword,
    getNotifiationSettings,
    getCountrydetailsByIp,
    getAllMenusByUserID
}