import jwt from 'jwt-decode';
import { UserService } from './RUserService';
import { CookieService } from './CookieService';
import { CryptoService } from './CryptoService';
import { AssignmentService } from './AssignmentService';
const cookieName = "_secure_auth-token_new_enc";
const cookie_Name_Country = "_cookie_country";
const cookie_Name_Dates_From = "_cookie_date_from";
const cookie_Name_Dates_To = "_cookie_date_to";
const cookieExpairy = 10;
const cookie_Role_Permission = "cookie_Role_Permission";
const cookie_Role_Permission_menus = "cookie_Role_Permission_menus";
const _headerKey = "AsLwgPQfgR7XMxg5chaj9i5UazBKIt+PlF3y6wye29o=";
const isAuthenticatedUser = () => {
    if (isExpairyToken()) {
        CookieService.clearCookie(cookieName);
        CookieService.clearCookie(cookie_Role_Permission);
        CookieService.clearCookie(cookie_Role_Permission_menus);
        return false;
    } else {
        return true;
    }
}
const logout = async () => {
    try {
        const res = await UserService.Logout();

        if (res.isSuccess) {
            CookieService.clearCookie(cookieName);
            CookieService.clearCookie(cookie_Role_Permission_menus);
            CookieService.clearCookie(cookie_Role_Permission);
            AssignmentService.clearAssignmentCookies();
            window.location.href = "/";
        }
    } catch (error) {
        console.log(error)
    }

}
const isExpairyToken = () => {
    let token = CookieService.getCookie(cookieName);
    if (token !== null && token !== "" && token !== undefined) {
        const decode = jwt(CryptoService.CryptoJSDecrypt(token));
        if (!decode) {
            return true;
        }
        if (new Date().getTime() < decode.exp) {
            CookieService.clearCookie(cookieName);
            return true;
        } else {
            return false;
        }
    } else {
        return true;
    }

}
const getCurrentUser = () => {
 
    const token = CookieService.getCookie(cookieName);
    const currentUser = {
        loginId: null,
        encLoginId: null,
        userId: null,
        encUserId: null,
        roleId: null,
        encRoleId: null,
        roleName: null,
        encRoleName: null,
        userName: null,
        encUserName: null,
        userTypeCode: null,
        encUserTypeCode: null,
        userTypeId: null,
        encUserTypeId: null,
        userTypeName: null,
        encUserTypeName: null,
        referenceID: null,
        encReferenceID: null,
        agencyID: null,
        userCountryID: null,
        userCountryName: null,
        userEmail: null,
        userContact: null
    };
    if (token) {
        let decode = jwt(CryptoService.CryptoJSDecrypt(token));
        if (!isExpairyToken()) {
            currentUser.loginId = decode.LoginId;
            currentUser.roleId = decode.RoleId;
            currentUser.userId = decode.UserId;
            currentUser.roleName = decode.RoleName;
            currentUser.userName = decode.UserName;
            currentUser.userTypeCode = decode.UserTypeCode;
            currentUser.userTypeId = decode.UserTypeId;
            currentUser.userTypeName = decode.UserTypeName;
            currentUser.referenceID = decode.ReferenceID;
            currentUser.agencyID = decode.AgencyID;
            currentUser.userCountryID = decode.UserCountryID;
            currentUser.userCountryName = btoa(CryptoService.CryptoJSEncrypt(decode.UserCountryName));
            currentUser.userEmail = decode.UserEmail;
            currentUser.userContact = decode.UserContact;
        } else {
            currentUser.loginId = null;
            currentUser.encLoginId = null;
            currentUser.userId = null;
            currentUser.encUserId = null;
            currentUser.roleId = null;
            currentUser.encRoleId = null;
            currentUser.roleName = null;
            currentUser.encRoleName = null;
            currentUser.userName = null;
            currentUser.encUserName = null;
            currentUser.userTypeCode = null;
            currentUser.encUserTypeCode = null;
            currentUser.userTypeCode = null;
            currentUser.encUserTypeCode = null;
            currentUser.userTypeName = null;
            currentUser.encUserTypeName = null;
            currentUser.referenceID = null;
            currentUser.encReferenceID = null;
            currentUser.agencyID = null;
            currentUser.userCountryID = null;
            currentUser.userCountryName = null;
            currentUser.userEmail = null;
            currentUser.userContact = null;

        }
    } else {
        currentUser.loginId = null;
        currentUser.encLoginId = null;
        currentUser.userId = null;
        currentUser.encUserId = null;
        currentUser.roleId = null;
        currentUser.encRoleId = null;
        currentUser.roleName = null;
        currentUser.encRoleName = null;
        currentUser.userName = null;
        currentUser.encUserName = null;
        currentUser.userTypeCode = null;
        currentUser.encUserTypeCode = null;
        currentUser.userTypeCode = null;
        currentUser.encUserTypeCode = null;
        currentUser.userTypeName = null;
        currentUser.encUserTypeName = null;
        currentUser.referenceID = null;
        currentUser.encReferenceID = null;
        currentUser.agencyID = null;
        currentUser.userCountryID = null;
        currentUser.userCountryName = null;
        currentUser.userEmail = null;
        currentUser.userContact = null;
    }
    return currentUser;
}

const LoginUser = async (loginId, password, userTypeID) => {
    if (loginId !== "" && password !== "") {
        const res = await UserService.Login(loginId, password, userTypeID);
        if (res.isSuccess) {
            CookieService.setCookie(cookieName, CryptoService.CryptoJSEncrypt(res.token), cookieExpairy);
            try {
                const response = await UserService.getAllMenusByUserID();
                if (response.isSuccess) {
                    const data = response.apiData ? JSON.parse(response.apiData) : [];
                    let permissions = [];
                    let menu_header = [];
                    for (let i = 0; i < data.length; i++) {
                        if (data[i].Parent.URL !== "") {
                            permissions.push(data[i].Parent);
                        }
                        for (let j = 0; j < data[i].ChildMenu.length; j++) {
                            permissions.push(data[i].ChildMenu[j]);
                        }
                        if (data[i].Parent.MenuName !== "") {
                            menu_header.push(data[i]);
                        }
                    }
                    menu_header.sort((a, b) => {
                        if (a.Parent.MenuName === "Home") {
                            return -1;
                        } else if (b.Parent.MenuName === "Home") {
                            return 1;
                        } else {
                            return 0;
                        }
                    });
                    if (permissions.length > 0) {
                        CookieService.setCookie(cookie_Role_Permission_menus, CryptoService.CryptoJSEncrypt(JSON.stringify(menu_header)), cookieExpairy);
                        CookieService.setCookie(cookie_Role_Permission, CryptoService.CryptoJSEncrypt(JSON.stringify(permissions)), cookieExpairy);
                    } else {
                        CookieService.clearCookie(cookie_Role_Permission_menus);
                        CookieService.clearCookie(cookie_Role_Permission);
                    }
                }
            } catch (error) {

            }
        }
        return res;
    }
}

const GetPermissions = (url) => {
    try {
        const path = url.split('/')[3].split('?')[0];
        let externalPath = "";
        if (path === 'cvUrl') {
            externalPath = 'autoCVLink';
        } else if (path === 'appliedCvUrl') {
            externalPath = 'applied-cvs';
        } else if (path === 'appicantTabUrl') {
            externalPath = 'applicant-dashboard';
        }
        else {
            externalPath = path;
        }
        const response = CryptoService.CryptoJSDecrypt(CookieService.getCookie(cookie_Role_Permission));
        const res = JSON.parse(response);
        if (res.length > 0) {
            for (let k = 0; k < res.length; k++) {
                if (res[k].URL === "/" + externalPath) {
                    return true;
                }
            }
        }
    } catch (error) {
        return false;
    }
}
const ResetLogin = async (currentUserID) => {
    if (currentUserID !== "") {
        const res = await UserService.ResetLogin(currentUserID);
        return res;
    }
}

const getApiAuthorizationConfig = () => {
    let config = "";
    if (CryptoService.CryptoJSDecrypt(CookieService.getCookie(cookieName))) {
        config = 'Bearer ' + CryptoService.CryptoJSDecrypt(CookieService.getCookie(cookieName))
    }
    return config;
}
const IsCookieBlockedByClient = () => {
    if (!navigator.cookieEnabled) {
        return true;
    } else {
        return false;
    }
}
const getFileNameForCurrentTimeStamp = () => {
    const today = new Date();
    const yyyy = today.getFullYear();
    let mm = today.getMonth() + 1; // Months start at 0!
    let dd = today.getDate();

    if (dd < 10) dd = '0' + dd;
    if (mm < 10) mm = '0' + mm;

    const formattedToday = dd + '_' + mm + '_' + yyyy;
    const timeStamp = new Date().toLocaleTimeString().split(":").join("_").replace(" ", "_");
    return formattedToday + "__" + timeStamp;
}
const isNullOrEmpty = (v) => {
    if (v === "" || v === null || v === undefined || v === 0 || v === "0") {
        return true;
    } else {
        return false;
    }
}
const get_url_parms_country = () => {
    let qs = "";
    var url = window.location.search;
    if (url) {
        try {
            const queryParams = new URLSearchParams(window.location.search);
            const countryID = queryParams.get("country");
            const countryName = queryParams.get("couName");

            qs = !isNullOrEmpty(countryName) ? `?country=${countryID}&couName=${countryName}` : `?country=${countryID}`;
        } catch (error) {
            console.error(error);
        }
    }
    return qs;
}

const get_countryID = () => {
    let qs = "";
    var url = window.location.search;
    if (url) {
        const queryObj = JSON.parse('{"' + url.replace(/"/g, '\\"').replace(/&/g, '","').replace(/=/g, '":"').replace("?", "") + '"}');
        try {

            qs = queryObj.country;
        } catch (error) {
            console.error(error)
        }
    }
    return qs;
}
const IsNullOrEmpty = (v) => {
    if (v === "" || v === null || v === undefined || v === 0 || v === "0") {
        return true;
    } else {
        return false;
    }
}
const set_country = (data) => {
    CookieService.setCookie(cookie_Name_Country, CryptoService.CryptoJSEncrypt(data), cookieExpairy);
}
const get_country = () => {
    if (!IsNullOrEmpty(CryptoService.CryptoJSDecrypt(CookieService.getCookie(cookie_Name_Country)))) {
        return CryptoService.CryptoJSDecrypt(CookieService.getCookie(cookie_Name_Country));
    } else {
        return "";
    }
}
const get_AppliedList_Enum = {
    'APPLIED_CANDIDATE_LIST': 1,
    'APPLIED_CLIENT_LIST': 2
}
const get_Date_Enum = {
    'FROM_DATE': 1,
    'TO_DATE': 2
}
const set_from_date = (v) => {
    CookieService.setCookie(cookie_Name_Dates_From, CryptoService.CryptoJSEncrypt(v), cookieExpairy);
}
const set_to_date = (v) => {
    CookieService.setCookie(cookie_Name_Dates_To, CryptoService.CryptoJSEncrypt(v), cookieExpairy);
}
const get_date = (num) => {
    try {
        if (num === get_Date_Enum.FROM_DATE) {
            return CryptoService.CryptoJSDecrypt(CookieService.getCookie(cookie_Name_Dates_From));
        }
        if (num === get_Date_Enum.TO_DATE) {
            return CryptoService.CryptoJSDecrypt(CookieService.getCookie(cookie_Name_Dates_To));
        }
    } catch (error) {
        return "";
    }
}
const clear_date = () => {
    CookieService.clearCookie(cookie_Name_Dates_From);
    CookieService.clearCookie(cookie_Name_Dates_To);
}
const isUserHasCountryAccess = async () => {
    let response = {
        msg: "",
        isSuccess: false
    }
    const res = await UserService.get_user_Country_By_IP();
    if (getCurrentUser().userName === "Admin") {
        response.isSuccess = true;
        response.msg = `success`;
    } else {
        if (!IsNullOrEmpty(get_countryID()) || get_countryID() === "all") {
            if (res.c_id != parseInt(get_countryID())) {
                response.isSuccess = false;
                response.msg = `This service not available,Please go to home page and press ${res.c_name} button`;
            }
        } else {
        }
    }
    return response;
}

const get_country_name = () => {
    let qs = "";
    try {
        const queryParams = new URLSearchParams(window.location.search);
        const countryName = queryParams.get("couName");
        qs = countryName;//decodeURIComponent(countryName);
    } catch (error) {
        console.error(error)
    }
    return qs;
}
const isCountryAccessForCurrentUser = async () => {
    if (getCurrentUser().roleId === "1") {
        return true;
    }
    let isAccess = false;
    try {
        const countryID = new URLSearchParams(window.location.search).get("country");
        const res = await UserService.getCountrydetailsByIp();
        if (res.isSuccess) {
            const c_data = JSON.parse(res.data)[0].CountryID;
            if (getCurrentUser().userCountryID === countryID && getCurrentUser().userCountryID === c_data.toString()) {
                isAccess = true;
            }
        }
    } catch (error) {
        console.log(error)
        isAccess = false;
    }
    return isAccess;
}
const CLICK_TO_CALL_REQUEST_PAGE_ENUM = {
    "operator_verification": 1,
    "operator_verification_notapplied": 2,
    "mothlyappliedlist": 3,
    "applicant_dashboard": 4,
    "manual_search_page": 5,
    "auto_search_page": 6,
    "Manage_Candidate": 7
}
const FILE_DOWNLOAD_ENUM = {
    "RESUME": 1,
    "PASSPORT": 2,
    "DRIVINGLICENSE": 3,
    "VISA": 4,
    "TICKETS": 5,
    "MEDICAL": 6,
    "PCC": 7,
    "EDUCATIONCERTIFICATE": 8,
    "EXPERIENCECERTIFICATE": 9,
    "OTHERS": 10,
    "CDC": 11,
    "PHOTO": 12
}
const FILE_DL_RESTRICTION_ENUM = {
    "MAX_DOWNLOAD_RESUME_PER_CLICK": 20
}
const MASS_EMAIL_SMS_TAB_ENUM = {
    "Application": "Application",
    "Shortlist": "Shortlist",
    "InProgress": "InProgress",
    "Selected": "Selected",
    "Rejected": "Rejected"
}
const get_CountryByIP = async () => {
    const data = await UserService.getCountrydetailsByIp();
    return data;
}
const xmlToObject = (xmlString) => {
    var parser = new DOMParser();
    var xml = parser.parseFromString(xmlString, "text/xml");
    var obj = {};
    var root = xml.documentElement;
    for (var i = 0; i < root.children.length; i++) {
        var child = root.children[i];
        obj[child.nodeName] = child.textContent;
    }
    return obj;
}
const getAuthHeaderKey = () => {
    return _headerKey;
}
const Get_Decrypted_CountryName_FromUrl = () => {
    const cName = get_country_name();
    let decName = "";
    try {
        decName = CryptoService.CryptoJSDecryptWithOutParse(atob(cName));
    } catch (error) {
        console.log(error)
    }
    return decName.replace(/"/g, '');;
}
const getParameterByName = (name, url = window.location.href) => {
    let v = "";
    try {
        name = name.replace(/[\[\]]/g, '\\$&');
        var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
            results = regex.exec(url);
        if (!results) {
            v = null;
        }
        if (!results[2]) {
            v = "";
        }
        v = decodeURIComponent(results[2].replace(/\+/g, ' '));
    } catch (error) {
        console.warn("Error in decode url parm ", error);
        v = "";
    }
    return v;
}
export const AuthService = {
    isAuthenticatedUser,
    isExpairyToken,
    getCurrentUser,
    getApiAuthorizationConfig,
    LoginUser,
    ResetLogin,
    logout,
    get_countryID,
    getFileNameForCurrentTimeStamp,
    get_url_parms_country,
    set_country,
    get_country,
    set_from_date,
    set_to_date,
    get_date,
    clear_date,
    get_country_name,
    isUserHasCountryAccess,
    isCountryAccessForCurrentUser,
    CLICK_TO_CALL_REQUEST_PAGE_ENUM,
    FILE_DOWNLOAD_ENUM,
    FILE_DL_RESTRICTION_ENUM,
    MASS_EMAIL_SMS_TAB_ENUM,
    GetPermissions,
    get_CountryByIP,
    xmlToObject,
    getAuthHeaderKey,
    Get_Decrypted_CountryName_FromUrl,
    getParameterByName
}