import React, { useEffect, useState } from 'react'
import Backdrop from '@mui/material/Backdrop';
import NewLoader from '../../ErrorPage/NewLoader';
import { AdvancesearchResultService } from '../../services/AdvancesearchResultService';
import { useParams } from 'react-router-dom';
import { useLocation, useNavigate } from 'react-router-dom';


function ManageKeyWord() {
    const [openLoader, setopenLoader] = useState(false);
    const [recorcCount, setrecorcCount] = useState(0);
    const [showInput, setShowInput] = useState(true);
    const [positionFound, setPositionFound] = useState();
    const [positionNotFound, setPositionNotFound] = useState();
    const navigate = useNavigate();
    const candidateId = useLocation().state;
    
    const goBack = () => {
        navigate({pathname:'/protected'}, { state: candidateId });
    };

    const handlePositionFoundOnSubmit = async (e) => {
        e.preventDefault();
        try {
            setopenLoader(true)
            const res = await AdvancesearchResultService.GetManagedKeyWord(positionFound);
            setopenLoader(false)
            if (res.isSuccess) {
                setrecorcCount(res.recordCount);
                alert("Result Found :" + res.recordCount);
                setopenLoader(false);

            } else {
                setopenLoader(false);
            }
        } catch (error) {
            setopenLoader(false);
        }

    }

    const handlePositionNotFoundOnSubmit = (e) => {
        e.preventDefault();
        setopenLoader(false);
        console.log(positionFound);

    }



    return (
        <>
            <Backdrop
                sx={{ color: '#FF5C35', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={openLoader}
            >
                <NewLoader />
            </Backdrop>

            <div>

                <button className='btn btn-success m-3' onClick={goBack}>Go Back</button>
            </div>

            <div className="container px-lg-4" style={{ backgroundColor: "#FFFFFF", padding: "30px 15px" }}>

                <form onSubmit={handlePositionFoundOnSubmit} className='border border-secondary rounded p-3'>
                    <div class="form-group">
                        <label htmlFor="position">Enter Position To Search</label>
                        <input type="text" class="form-control" name='position' placeholder="Example: Drilling Operator" onChange={(e) => setPositionFound(e.target.value)} required />
                        {/* {recorcCount > 0 ? <small id="emailHelp" class="form-text text-muted">Result Found :{recorcCount}</small> : ""} */}
                        <br></br>
                        <button type="submit" class="btn btn-primary mt-1">Check</button>
                    </div>
                </form>

                <hr style={{ margin: "1rem 0px" }} />
                {/* <hr style={{ margin: "1rem 0px" }} />

                    <form onSubmit={handlePositionNotFoundOnSubmit} className='border border-secondary rounded p-3 mt-3'>


                        <div class="form-group">
                            <div class="alert alert-danger" role="alert">
                                Warning :  Add KeyWord below May Take about half and hour or more to process .
                            </div>
                            <label htmlFor="positionNotFound">Enter Position When Not Found</label>
                            <input class="form-control" type="text" id="positionNotFound" name="positionNotFound" placeholder="Example: Drilling Operator" onChange={(e) => setPositionNotFound(e.target.value)} required />
                            <button type="submit" class="btn btn-primary mt-1">Add keyWord</button>
                        </div>
                    </form> */}


            </div>


        </>
    )
}

export default ManageKeyWord;