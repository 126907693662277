import { DomainService } from "./RecruiterDomainService";
import axios from 'axios';
import { AuthService } from './AuthService';

const baseUrl = DomainService.GetBaseUrl() + "Admin/";

const getClientListByUserID = async (startIndex, searchText) => {
    const res = await fetch(baseUrl + "GetClientListByUserID?startIndex=" + startIndex + "&userID=" + AuthService.getCurrentUser().userId + "&searchText=" + searchText, {
        method: "GET",
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': AuthService.getApiAuthorizationConfig()
        },
    });
    const data = await res.json();
    return data;
}

const getAssignmentListByClientID = async (clientID) => {
    const res = await fetch(baseUrl + "GetAssignmentListByClientID?encClientID=" + clientID + "&userID=" + AuthService.getCurrentUser().userId, {
        method: "GET",
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': AuthService.getApiAuthorizationConfig()
        },
    });
    const data = await res.json();
    return data;
}

const getClientList = async (searchText) => {
    const res = await fetch(baseUrl + "GetClientList?userID=" + AuthService.getCurrentUser().userId + "&searchText=" + searchText, {
        method: "GET",
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': AuthService.getApiAuthorizationConfig()
        },
    });
    const data = await res.json();
    return data;
}

const getCoordinatorList = async (searchText) => {
    const res = await fetch(baseUrl + "GetAssignmentCoordinatorList?userID=" + AuthService.getCurrentUser().userId + "&searchText=" + searchText, {
        method: "GET",
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': AuthService.getApiAuthorizationConfig()
        },
    });
    const data = await res.json();
    return data;
}

const saveCustomField = async (reqData) => {
    const res = await fetch(baseUrl + "SaveOrUpdateCandidateCustomField", {
        method: "POST",
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': AuthService.getApiAuthorizationConfig()
        },
        body: JSON.stringify(reqData)
    });
    const data = await res.json();
    return data;
}

const getLocation = async () => {
    const res = await fetch(baseUrl + "GetLocation", {
        method: "GET",
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': AuthService.getApiAuthorizationConfig()
        },
    });
    const data = await res.json();
    return data;
}

const downloadCandidateData = async (assignmentID) => {
    const res = await fetch(baseUrl + "DownloadCandidateData?encAssignmentID=" + assignmentID + "&UserID=" + AuthService.getCurrentUser().referenceID, {
        method: "GET",
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': AuthService.getApiAuthorizationConfig()
        },
    });
    const data = await res.json();
    return data;
}

const massEmailSend = async (reqData) => {
    try {
        const config = {
            headers: {
                'content-type': 'multipart/form-data',
                'Authorization': AuthService.getApiAuthorizationConfig()
            },
        };
        const res = await axios.post(baseUrl + "SendEmailWithAttachment", reqData, config).then((response) => {
            console.log(response);
            return response;
        });
        return res;
    } catch (error) {
        console.log(error);
    }
}

const saveNewCandidate = async (reqData) => {
    try {
        const config = {
            headers: {
                'content-type': 'multipart/form-data',
                'Authorization': AuthService.getApiAuthorizationConfig()
            },
        };
        const res = await axios.post(baseUrl + "SaveNewCandidate", reqData, config).then((response) => {
            console.log(response);
            return response;
        });
        return res;
    } catch (error) {
        console.log(error);
    }
}

const sendMessage = async (reqData) => {
    try {
        const config = {
            headers: {
                'content-type': 'multipart/form-data',
                'Authorization': AuthService.getApiAuthorizationConfig()
            },
        };
        const res = await axios.post(baseUrl + "SendMessage", reqData, config).then((response) => {
            console.log(response);
            return response;
        });
        return res;
    } catch (error) {
        console.log(error);
    }
}

const getJobPostingsByassignmentId = async (assignmentId) => {
    const res = await fetch(baseUrl + "GetJobPostingByOnAssignment?encAssignmentID=" + assignmentId + "&UserID=" + AuthService.getCurrentUser().userId, {
        method: "GET",
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': AuthService.getApiAuthorizationConfig()
        }
    });
    const data = await res.json();
    return data;
}

//documents
const getCandidateDocumentByCandidateID = async (canID, encJobPostingID, isNotFirstDocView) => {
    const res = await fetch(baseUrl + "GetCandidateDocumentByCandidateID?encCandidateID=" + canID + "&encJobPostingID=" + encJobPostingID + "&userID=" + AuthService.getCurrentUser().userId + "&isNotFirstDocView=" + isNotFirstDocView, {
        method: "GET",
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': AuthService.getApiAuthorizationConfig()
        },
    });
    const data = await res.json();
    return data;
}

const getDocTypes = async () => {
    const res = await fetch(baseUrl + "GetDocTypes", {
        method: "GET",
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': AuthService.getApiAuthorizationConfig()
        },
    });
    const data = await res.json();
    return data;
}

const saveOrUpdateDocument = async (formData) => {
    const config = {
        headers: {
            'Authorization': AuthService.getApiAuthorizationConfig(),
            'Content-Type': 'multipart/form-data'
        },
    };
    const res = await axios.post(baseUrl + "SaveOrUpdateDocument", formData, config);
    return res;
}

const deleteCandidateDocument = async (encDocID) => {
    const res = await fetch(baseUrl + "DeleteCandidateDocument?encDocID=" + encDocID, {
        method: "GET",
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': AuthService.getApiAuthorizationConfig()
        },
    });
    const data = await res.json();
    return data;
}

const downloadCandidateDocument = async (encDocID) => {
    const res = await fetch(baseUrl + "DownloadCandidateDocument?encDocID=" + encDocID, {
        method: "GET",
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': AuthService.getApiAuthorizationConfig()
        },
    });
    const data = await res.json();
    return data;
}
const moveToMobilization = async (reqData) => {
    const res = await fetch(baseUrl + "MoveToMobilization", {
        method: "POST",
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': AuthService.getApiAuthorizationConfig()
        },
        body: JSON.stringify(reqData)
    });
    const data = await res.json();
    return data;
}
const undoMobilization = async (reqData) => {
    const res = await fetch(baseUrl + "UndoMobilization", {
        method: "POST",
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': AuthService.getApiAuthorizationConfig()
        },
        body: JSON.stringify(reqData)
    });
    const data = await res.json();
    return data;
}

const searchByMultiPPNo = async (passports) => {
    const res = await fetch(baseUrl + "DownloadCandidateDocument?passports=" + passports, {
        method: "GET",
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': AuthService.getApiAuthorizationConfig()
        },
    });
    const data = await res.json();
    return data;
}

const save_InterviewInfo = async (reqData) => {
    const res = await fetch(baseUrl + "SaveInterviewInfo", {
        method: "POST",
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': AuthService.getApiAuthorizationConfig()
        },
        body: JSON.stringify(reqData)
    });
    const data = await res.json();
    return data;
}

const get_InterviewInfo_By_JpID = async (jpID) => {
    const res = await fetch(baseUrl + "GetInterviewInfo?jpID=" + jpID, {
        method: "GET",
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': AuthService.getApiAuthorizationConfig()
        },
    });
    const data = await res.json();
    return data;
}

const delete_InterviewDetails_By_InterviewID = async (interviewID) => {
    const res = await fetch(baseUrl + "DeleteInterviewDetailsByID?interviewID=" + interviewID, {
        method: "GET",
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': AuthService.getApiAuthorizationConfig()
        },
    });
    const data = await res.json();
    return data;
}

const get_Scheduled_InterviewLocation_IDs = async (encJpID) => {
    const res = await fetch(baseUrl + "GetScheduledInterviewLocationIDs?encJpID=" + encJpID, {
        method: "GET",
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': AuthService.getApiAuthorizationConfig()
        },
    });
    const data = await res.json();
    return data;
}

const get_ShortListed_Candidates = async (reqData) => {
    const res = await fetch(baseUrl + "GetShortListedCandidates", {
        method: "POST",
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': AuthService.getApiAuthorizationConfig()
        },
        body: JSON.stringify(reqData)
    });
    const data = await res.json();
    return data;
}

const update_Candidates_Interview_Location = async (jpID, locID, CandidateIDs, reqdata) => {
    try {
        const res = await fetch(baseUrl + "UpdateCandidatesInterviewLocation?encJpID=" + encodeURIComponent(jpID) + "&locID=" + locID + "&encCandidateIDs=" + encodeURIComponent(CandidateIDs) + "&UserID=" + encodeURIComponent(AuthService.getCurrentUser().userId), {
            method: "POST",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': AuthService.getApiAuthorizationConfig()
            },
            body: JSON.stringify(reqdata) // Send reqdata in the body
        });

        if (!res.ok) {
            throw new Error('Network response was not ok' + res.statusText);
        }

        const data = await res.json();
        return data;
    } catch (error) {
        console.error('There was a problem with the fetch operation:', error);
        throw error;
    }
}

const get_InProgress_Candidates = async (reqData) => {
    const res = await fetch(baseUrl + "GetInProgressCandidates", {
        method: "POST",
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': AuthService.getApiAuthorizationConfig()
        },
        body: JSON.stringify(reqData)
    });
    const data = await res.json();
    return data;
}

const saveCandidateStatuses = async (reqData) => {
    const res = await fetch(baseUrl + "SaveCandidateStatuses", {
        method: "POST",
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': AuthService.getApiAuthorizationConfig()
        },
        body: JSON.stringify(reqData)
    });
    const data = await res.json();
    return data;
}

const getAppliedCandidates = async (pageIndex, jobpostingID, searchText, pageSize, passports, countryID) => {
    const res = await fetch(baseUrl + "GetAppliedCandidates?jobpostingID=" + jobpostingID + "&userID=" + AuthService.getCurrentUser().referenceID + "&pageIndex=" + pageIndex + "&searchText=" + searchText + "&pageSize=" + pageSize + "&filterPP=" + passports + "&countryId=" + countryID, {
        method: "GET",
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': AuthService.getApiAuthorizationConfig()
        },
    });
    const data = await res.json();
    return data;
}

const getSelectedOrRejectedCandidates = async (jobID, pageIndex, pageSize, ppFilter, searchText, interviewID, type, countryID) => {
    const reqData = {
        JobpostingID: jobID,
        UserID: AuthService.getCurrentUser().referenceID,
        PageIndex: pageIndex,
        SearchText: searchText,
        PageSize: pageSize,
        FilterPP: ppFilter,
        InterviewID: interviewID,
        OperationType: type,
        CountryID: countryID
    }
    const res = await fetch(baseUrl + "GetSelectedOrRejectedCandidates", {
        method: "POST",
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': AuthService.getApiAuthorizationConfig()
        },
        body: JSON.stringify(reqData)
    });
    const data = await res.json();
    return data;
}

const viewCandidateDocument = async (encCandidateID) => {
    const res = await fetch(baseUrl + "GetAppliedCandidateResume?encCandidateID=" + encCandidateID, {
        method: "GET",
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': AuthService.getApiAuthorizationConfig()
        },
    });
    const data = await res.json();
    return data;
}

const get_TabsCount = async (encJpID) => {
    const res = await fetch(baseUrl + "GetTabsCount?encJPID=" + encJpID, {
        method: "GET",
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': AuthService.getApiAuthorizationConfig()
        },
    });
    const data = await res.json();
    return data;
}

const get_PreSigned_S3_File = async (url) => {
    const res = await fetch(url, {
        method: "GET",
    });
    const data = await res.text();
    return data;
}

const get_ApplicantDashboard_Revert_Options = async (encJpID, tabName) => {
    const res = await fetch(baseUrl + "GetApplicantDashboardRevertOptions?encJPID=" + encJpID + "&tabName=" + tabName, {
        method: "GET",
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': AuthService.getApiAuthorizationConfig()
        },
    });
    const data = await res.json();
    return data;
}

const Update_Candidate_ResumeStatus = async (reqData) => {
    const res = await fetch(baseUrl + "UpdateCandidateResumeStatus", {
        method: "POST",
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': AuthService.getApiAuthorizationConfig()
        },
        body: JSON.stringify(reqData)
    });
    const data = await res.json();
    return data;
}
const GetReportsByUserId = async (userId) => {
    const res = await fetch(baseUrl + "GetReportsByUserId?encUserId=" + userId, {
        method: "GET",
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': AuthService.getApiAuthorizationConfig()
        },
    });
    const data = await res.json();
    return data;
}

const GetMonthlyAndWeaklyReportByAgencyId = async (agencyID) => {
    const res = await fetch(baseUrl + "GetMonthlyAndweaklyReportByAgencyId?agencyID=" + agencyID, {
        method: "GET",
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': AuthService.getApiAuthorizationConfig()
        },
    });
    const data = await res.json();
    return data;
}

export const AdminService = {
    getClientListByUserID,
    getAssignmentListByClientID,
    getAppliedCandidates,
    saveCustomField,
    getLocation,
    downloadCandidateData,
    massEmailSend,
    saveNewCandidate,
    sendMessage,
    getCandidateDocumentByCandidateID,
    getDocTypes,
    saveOrUpdateDocument,
    deleteCandidateDocument,
    downloadCandidateDocument,
    searchByMultiPPNo,
    getJobPostingsByassignmentId,
    moveToMobilization,
    undoMobilization,
    getCoordinatorList,
    getClientList,
    save_InterviewInfo,
    get_InterviewInfo_By_JpID,
    delete_InterviewDetails_By_InterviewID,
    get_Scheduled_InterviewLocation_IDs,
    get_ShortListed_Candidates,
    update_Candidates_Interview_Location,
    get_InProgress_Candidates,
    saveCandidateStatuses,
    getSelectedOrRejectedCandidates,
    viewCandidateDocument,
    get_TabsCount,
    get_PreSigned_S3_File,
    get_ApplicantDashboard_Revert_Options,
    Update_Candidate_ResumeStatus,
    GetReportsByUserId,
    GetMonthlyAndWeaklyReportByAgencyId
}